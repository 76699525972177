<script setup>
import { ref, watch, computed } from "vue";
import { useImage } from "@vueuse/core";
import useVisibleIntersection from "../../composables/useVisibleIntersection";

const emit = defineEmits(["loaded"]);

const props = defineProps({
  data: [Object, null],
  sizes: [String, null],
  playsinline: Boolean,
  loadingThumbnail: Boolean,
});

const element = ref(null);
const isVisible = useVisibleIntersection(element, false);

const path = computed(() => {
  let path =
    props && props.data && props.data.attributes
      ? { src: props.data.attributes }
      : null;

  return path;
});

const { isLoading } = useImage(null);

const loading = computed(() => {
  if (!isLoading.value) {
    emit("loaded");
  }
  return unref(isLoading);
});

const objectPosition = computed(
  () => `${props.data.focalPoint.x * 100}% ${props.data.focalPoint.y * 100}%`
);

const objectFit = computed(() =>
  ["svg", "gif"].includes(props.data.extension) ? "contain" : "cover"
);
</script>

<template>
  <img
    ref="element"
    class="image"
    :class="[loading, isVisible ? 'visible' : 'hidden']"
    v-bind="data.attributes"
    :sizes="sizes"
    :style="{
      '--object-fit': objectFit,
      '--object-position': objectPosition,
      ...(loadingThumbnail
        ? { 'background-image': `url(${data.placeholder})` }
        : {}),
    }"
  />
</template>

<style lang="postcss" scoped>
img {
  width: 100%;
  height: 100%;

  background-size: var(--object-fit);
  /* background-size: contain; */
  background-position: var(--object-position);
  background-repeat: no-repeat;

  object-fit: var(--object-fit);
  /* object-fit: contain; */
  object-position: var(--object-position);

  display: flex;

  font-size: units(1);
  position: relative;

  &.visible {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
  }

  &:before {
    background: var(--off-white);
    content: "\1f4f7"" " attr(alt);
    display: block;
    position: absolute;
    top: 0rem;
    width: 100%;
  }
}
</style>
